<script setup lang="ts">
import type { BookingRequestPriceResponse, OptionPrice } from '@yescapa-dev/ysc-api-js/legacy'
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import { SECOND_DRIVER_STATES } from '@yescapa-dev/ysc-api-js/modern'
import type { BookingsPrices } from '~/types/bookingRequest'

interface Props {
  totalKm?: number
  kmOption?: number | null
  prices: BookingsPrices | BookingRequestPriceResponse
  userType: 'guest' | 'owner'
  isSelfInsured?: boolean
  emphasisClasses?: string
}

const props = withDefaults(defineProps<Props>(), {
  totalKm: 0,
  kmOption: null,
  isSelfInsured: false,
  emphasisClasses: undefined,
})

const { getReferentialMaps } = useReferentialStore()
const formatKilometerPerDay = useFormatKilometerPerDay()

const isGuest = computed(() => props.userType === Constants.USERS.TYPES.GUEST)

interface OptionPriceWithName extends OptionPrice {
  name: string
}

const bookingOptions = computed<OptionPriceWithName[]>(() => props.prices.options
  .filter(opt => !opt.mandatory)
  .map(opt => ({
    ...opt,
    name: getReferentialMaps('option_display')[opt.option_name]?.name,
  })),
)

const bookingOptionsMandatory = computed<OptionPriceWithName[]>(() => props.prices.options
  .filter(opt => opt.mandatory)
  .map(opt => ({
    ...opt,
    name: getReferentialMaps('option_display')[opt.option_name]?.name,
  })),
)

const bookingOptionsTotal = computed(() => bookingOptions.value.reduce((acc, cur) => {
  acc += cur.price
  return acc
}, 0))

const totalPrice = computed(() => 'price_raw' in props.prices && props.prices.price_raw ? props.prices.price_raw : props.prices.price)
const correctedPrice = computed(() => 'price_raw' in props.prices && isGuest.value && props.prices.price_raw && props.prices.price.value !== props.prices.price_raw.value ? props.prices.price.value : null)
const priceIntegralProtection = computed(() => props.prices.price_rental_cover?.value ? props.prices.price_rental_cover : props.prices.price_insurance)

const { bookingGuest } = storeToRefs(useBookingsGuestStore())
const shouldDisplaySecondDriver = computed(() => bookingGuest.value && (bookingGuest.value.second_driver === SECOND_DRIVER_STATES.INCLUDED || bookingGuest.value.second_driver === SECOND_DRIVER_STATES.SELECTED))
</script>

<template>
  <table class="table w-full table-auto">
    <thead class="sr-only">
      <tr>
        <th>{{ $t('commons.details') }}</th>
        <th>{{ $t('commons.price') }}</th>
      </tr>
    </thead>
    <tbody>
      <AppPriceDetailsRow
        v-if="prices.price_owner_per_day.value !== null"
        :price="prices.price_owner.value"
        :currency="getReferentialMaps('currency')[prices.price_owner.currency]"
        :emphasis-classes="emphasisClasses"
        first-line
      >
        {{ $n(prices.price_owner_per_day.value, getReferentialMaps('currency')[prices.price_owner_per_day.currency]) }} x
        {{ $n(prices.nb_days, { style: 'unit', unit: 'day', unitDisplay: 'long' }) }}
        <template #description>
          <template v-if="isGuest">
            {{ $t('components.app_price_details.days_count.description.guest') }}
          </template>
          <template v-else>
            {{ $t('components.app_price_details.days_count.description.owner') }}
          </template>
        </template>
      </AppPriceDetailsRow>

      <AppPriceDetailsRow
        v-if="prices.price_discount_early.value"
        :price="prices.price_discount_early.value"
        :currency="getReferentialMaps('currency')[prices.price_discount_early.currency]"
        :emphasis-classes="emphasisClasses"
        substract
      >
        {{
          $t('components.app_price_details.discount_early.text_dynamic', {
            percent_string: $n(prices.early_factor / 100, 'percent'),
          })
        }}
        <template #description>
          {{ $t('components.app_price_details.discount_early.description') }}
        </template>
      </AppPriceDetailsRow>

      <AppPriceDetailsRow
        v-if="prices.price_discount_weekly.value"
        :price="prices.price_discount_weekly.value"
        :currency="getReferentialMaps('currency')[prices.price_discount_weekly.currency]"
        :emphasis-classes="emphasisClasses"
        substract
      >
        {{
          $t('components.app_price_details.discount_weekly.text_dynamic', {
            percent_string: $n(prices.weekly_factor / 100, 'percent'),
          })
        }}
        <template #description>
          {{ $t('components.app_price_details.discount_weekly.description') }}
        </template>
      </AppPriceDetailsRow>

      <AppPriceDetailsRow
        :price="prices.price_km.value"
        :currency="getReferentialMaps('currency')[prices.price_km.currency]"
        :emphasis-classes="emphasisClasses"
      >
        {{
          $t('data.booking.km_option.km_per_day_dynamic', {
            km_option_string: formatKilometerPerDay({ count: kmOption ? kmOption : 0 }),
          })
        }}
        <template
          v-if="totalKm !== 0"
          #description
        >
          {{
            $t('data.booking.km_option.description_total_dynamic', {
              km_total_string: $n(totalKm, { style: 'unit', unit: 'kilometer' }),
            })
          }}
        </template>
      </AppPriceDetailsRow>

      <AppPriceDetailsRow
        v-for="opt in bookingOptionsMandatory"
        :key="`mandatory-option-${opt.id}`"
        :price="opt.price"
        :currency="getReferentialMaps('currency')[prices.price_options.currency]"
        :emphasis-classes="emphasisClasses"
      >
        {{ opt.name }}
        <template
          v-if="opt.mandatory"
          #description
        >
          {{ $t('components.app_price_details.mandatory_description') }}
        </template>
      </AppPriceDetailsRow>

      <AppPriceDetailsRow
        v-if="bookingOptions.length"
        :price="bookingOptionsTotal"
        :currency="getReferentialMaps('currency')[prices.price_options.currency]"
        :emphasis-classes="emphasisClasses"
      >
        {{ $t('components.app_price_details.options') }}
        <template #description>
          <span
            v-for="opt in bookingOptions"
            :key="`booking-option-${opt.id}`"
            class="block"
          >
            {{ opt.name }} {{ $n(opt.price, getReferentialMaps('currency')[prices.price_options.currency]) }}
          </span>
        </template>
      </AppPriceDetailsRow>

      <AppPriceDetailsRow
        v-if="shouldDisplaySecondDriver"
        :price="prices.price_second_driver.value"
        :currency="getReferentialMaps('currency')[prices.price_second_driver.currency]"
        :emphasis-classes="emphasisClasses"
      >
        {{ $t('components.app_booking_summary.second_driver.title') }}
      </AppPriceDetailsRow>

      <AppPriceDetailsRow
        v-if="prices.price_fees && prices.price_fees.value !== null"
        :price="prices.price_fees.value"
        :currency="getReferentialMaps('currency')[prices.price_fees.currency]"
        :substract="!isGuest"
        no-emphasis
      >
        <template #default>
          <template v-if="isGuest">
            <template v-if="isSelfInsured">
              {{ $t('components.app_price_details.price_fees.text.guest.splited') }}
            </template>
            <template v-else>
              {{ $t('components.app_price_details.price_fees.text.guest.merged') }}
            </template>
          </template>
          <template v-else>
            {{ $t('components.app_price_details.price_fees.text.owner') }}
          </template>
        </template>

        <template
          v-if="isGuest"
          #description
        >
          <template v-if="isSelfInsured">
            {{ $t('components.app_price_details.price_fees.description.guest.splited') }}
          </template>
          <template v-else>
            {{ $t('components.app_price_details.price_fees.description.guest.merged') }}
          </template>
        </template>
      </AppPriceDetailsRow>

      <AppPriceDetailsRow
        v-if="priceIntegralProtection && priceIntegralProtection.value && isGuest"
        :price="priceIntegralProtection.value"
        :currency="getReferentialMaps('currency')[priceIntegralProtection.currency]"
        no-emphasis
      >
        {{ $t('components.app_price_details.price_insurance.text') }}

        <template #description>
          {{ $t('components.app_price_details.price_insurance.description') }}
        </template>
      </AppPriceDetailsRow>

      <AppPriceDetailsRow
        v-if="'price_cancellation_insurance' in prices && prices.price_cancellation_insurance.value"
        :price="prices.price_cancellation_insurance.value"
        :currency="getReferentialMaps('currency')[prices.price_cancellation_insurance.currency]"
        no-emphasis
      >
        {{ $t('components.app_price_details.price_cancellation_insurance.text') }}
      </AppPriceDetailsRow>

      <AppPriceDetailsRow
        v-if="prices.price_coupon && prices.price_coupon.value"
        :price="prices.price_coupon.value"
        :currency="getReferentialMaps('currency')[prices.price_coupon.currency]"
        :emphasis-classes="emphasisClasses"
        substract
      >
        {{ $t(`commons.coupon`) }}
      </AppPriceDetailsRow>

      <AppPriceDetailsRow
        :price="totalPrice.value"
        :discount="isGuest ? prices.price_without_discount?.value : null"
        :corrected-price="correctedPrice"
        :currency="getReferentialMaps('currency')[totalPrice.currency]"
        :currency-used="isGuest && 'price_raw' in prices && prices.price !== prices.price_raw ? getReferentialMaps('currency')[prices.price.currency] : null"
        :emphasis-classes="emphasisClasses"
        bold-line
      >
        <template v-if="isGuest">
          {{ $t('components.app_price_details.total_price.text.guest') }}
        </template>
        <template v-else>
          {{ $t('commons.remuneration') }}
        </template>
      </AppPriceDetailsRow>
    </tbody>
  </table>
</template>
